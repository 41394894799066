body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #eee;
  min-height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.header,
.ui.header {
  font-family: Montserrat;
}
p,
div,
ul,
a,
input,
button {
  font-family: Lato, sans-serif;
}
#root {
  min-height: 100%;
}
#body-container {
  position: relative;
  min-height: 100vh;
}
#content {
  padding: 8em 0 12em;
  min-height: calc(100vh - 11.25em);
  min-height: 500px;
}
#site-header,
#site-footer {
  width: 100%;
  border-radius: 0;
  background-color: #0f2e4d;
  background-image: linear-gradient(to right, #096eb6, #1b3561);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}
#site-header {
  border-bottom: 5px solid #009bee;
}
#site-footer {
  border-top: 8px solid #009bee;
  padding: 1.75em 0;
  margin-top: 1em;
  position: absolute;
  bottom: 0;
}
.container img {
  max-width: 100%;
  margin: 1em 0;
}
#site-footer p {
  color: rgba(255, 255, 255, 0.5);
}
#site-footer .ui.divided.inverted.horizontal.list > .item,
.ui.divided.inverted.list > .item,
.ui.divided.inverted.list > .list {
  border-color: rgba(255, 255, 255, 0.5);
}
.ui.secondary.pointing.menu .dropdown .active.item {
  border: none;
}
.ui.blue.header {
  color: #009bee !important;
}
#login-logo {
  filter: brightness(0);
  margin: 0 auto;
  margin-top: 1em;
}
#menu-container {
  width: 1440px;
}
.ui.menu .item::before {
  background: none;
}
